
import { defineComponent, reactive, ref, onMounted } from 'vue'
import Location from '@/models/location'
import Facility from '@/models/facility'
import User from '@/models/user'
import http from "@/modules/httpclient"
import { ElNotification } from 'element-plus'

export default defineComponent({
    props:{
      loginUser:User
    },
    setup() {
        const searchCondition = reactive<Location>(new Location('', '', '', '', 0, ''))
        const locations = reactive<Location[]>([])
        const location = reactive<Location>(new Location('', '', '', '', 0, ''))
        const facilities = reactive<Facility[]>([])

        const isEdit = ref(false)
        const isDeleteVisible = ref(false)
        const isDialogVisible = ref(false)
        const isConfirmVisible = ref(false)
        const isSearchVisible = ref(false)
        const form = ref()
        const filter = ref('')

        const dispColumns = reactive({
            locationID: false,
            locationName: true,
            locationCode: true,
            facilityID: false,
            facilityName: true
        })

        const clearValidate = () => {
            form.value.clearValidate()
        }

        // 入力チェック
        const rules = {
            locationName: [
                {
                    required: true,
                    message: '必須入力です',
                    trigger: 'blur'
                }
            ],
            locationCode: [
                {
                    validator: async (rule, value, callback) => {
                        // facility_id と合わせてユニークにする
                        if(isEdit.value){
                            callback()
                        }
                        if(!location.facilityID){
                            // facility_id が未選択の場合は、一旦通す。必須なので登録前には必ずチェックすることになる
                            callback()
                        }

                        try{
                            const res = await http.get('/v1/locations',{
                                params:{
                                    locationCode:value,
                                    facilityID:location.facilityID,
                                    isDeleted:1 // 削除済みも含む
                                }
                            })
                            if(res.data.locations.length > 0){
                                callback(new Error('すでに使用されているコードです'))
                            }
                            else{
                                // OK
                                callback()
                            }
                        }
                        catch (reason){
                            ElNotification({ type: 'error', message: '予期しないエラーが発生しました' })
                            callback(new Error('予期しないエラーが発生しました'))
                        }
                    },
                    trigger: 'blur'
                },
                {
                    required: true,
                    message: '必須入力です',
                    trigger: 'blur'
                }
            ],
            facilityID: [
                {
                    required: true,
                    message: '選択必須です',
                    trigger: 'blur'
                }
            ]
        }

        // 検索
        const handleSearch = async () => {
            try {
                const res = await http.get('/v1/locations', { params: { 
                                locationID: searchCondition.locationID,
                                locationName: searchCondition.locationName,
                                locationCode: searchCondition.locationCode,
                                facilityID: searchCondition.facilityID,
                                isDeleted: searchCondition.isDeleted ? 1 : 0
                            }})
                locations.splice(0) // リストをクリア
                res.data.locations.map((l: any) =>  {
                    locations.push(new Location(l.locationID, l.locationName, l.locationCode, l.facilityID, l.isDeleted, l.facilityName))
                })

                if (locations.length > 0) {
                    ElNotification({ type: 'success', message: `${locations.length} 件ヒットしました` })
                } else {
                    ElNotification({ type: 'error', message: '検索結果がありません' })
                }
            }
            catch (reason: any){

                ElNotification({ type: 'error', message: '処理に失敗しました' })
                throw new Error(`search error: ${reason.response.status} ${reason.response.data.message}`)
            }
        }

        // 新規作成ダイアログ表示
        const showCreateDialog = () => {
            location.locationID = ''
            location.locationName = ''
            location.locationCode = ''
            location.facilityID = ''
            location.isDeleted = false

            isEdit.value = false
            isDeleteVisible.value = false
            isDialogVisible.value = true
        }

        // 新規作成
        const handleCreate = () => {
            // 入力チェック
            form.value.validate(async (valid) => { 
                if (!valid) {
                    // validation error
                    return
                }
                try {
                    const res = await http.post('/v1/locations', { 
                        locationID: location.locationID,
                        locationName: location.locationName,
                        locationCode: location.locationCode,
                        facilityID: location.facilityID,
                        isDeleted: location.isDeleted ? 1 : 0,
                        })
                    locations.push(new Location(res.data.location.locationID, location.locationName, location.locationCode, location.facilityID, location.isDeleted ? 1 : 0,
                        facilities[facilities.findIndex((f)=> f.facilityID === location.facilityID)].facilityName))
                    ElNotification({ type: 'success', message: '正常終了しました' })
                    isDialogVisible.value = false
                }
                catch(reason: any) {

                    ElNotification({ type: 'error', message: '処理に失敗しました' })
                    isDialogVisible.value = false
                    throw new Error(`create location error: ${reason.response.status} ${reason.response.data.message}`)
                }
                
            })
        }

        // 更新ダイアログ表示
        const showUpdateDialog = (idx: number, row: Location) => {

            location.locationID = row.locationID
            location.locationName = row.locationName
            location.locationCode = row.locationCode
            location.facilityID = row.facilityID
            location.isDeleted = row.isDeleted

            isEdit.value = true
            isDeleteVisible.value = row.isDeleted
            isDialogVisible.value = true
        }

        // 更新
        const handleUpdate = () => {
            // 入力チェック
            form.value.validate(async (valid) => {
                if (!valid) {
                    // validation error
                    return
                }

                try {
                    const res = await http.patch(`/v1/locations/${location.locationID}`, { 
                            locationID: location.locationID,
                            locationName: location.locationName,
                            locationCode: location.locationCode,
                            facilityID: location.facilityID,
                            isDeleted: location.isDeleted ? 1 : 0
                        })
                    const l = locations[locations.findIndex(i => i.locationID === location.locationID)]
                    l.locationName = location.locationName
                    l.locationCode = location.locationCode
                    l.facilityID = location.facilityID
                    l.isDeleted = location.isDeleted

                    ElNotification({ type: 'success', message: '正常終了しました' })
                    isDialogVisible.value = false
                }
                catch(reason: any){

                    ElNotification({ type: 'error', message: '処理に失敗しました' })
                    isDialogVisible.value = false
                    throw new Error(`update location error: ${reason.response.status} ${reason.response.data.message}`)
                }
                
                
            })
        }

        // 削除確認ダイアログ表示
        const showDeleteDialog = (idx: number, row: Location) => {
            location.locationID = row.locationID
            location.locationName = row.locationName
            location.locationCode = row.locationCode

            isConfirmVisible.value = true
        }

        // 削除
        const handleDelete = async () => {
            try {
                const res = await http.delete(`/v1/locations/${location.locationID}`)
                locations.splice(locations.findIndex(i => i.locationID === location.locationID), 1)
                ElNotification({ type: 'success', message: '正常終了しました' })
                isConfirmVisible.value = false
            }
            catch (reason: any){
                ElNotification({ type: 'error', message: '処理に失敗しました' })
                isConfirmVisible.value = false
                throw new Error(`delete location error: ${reason.response.status} ${reason.response.data.message}`)
            }
        }

        const getFacilities = async () => {
            try {
                const res = await http.get('/v1/facilities')
                res.data.facilities.map((f: any) =>  {
                    facilities.push(new Facility(f.facilityID, f.facilityName, f.facilityCode, f.brandID, 
                        f.zip, f.country, f.state, f.city, f.address, f.phone, f.longitude, f.latitude, f.range, f.picture, f.isDeleted))
                })
            }
            catch (reason){
                ElNotification({ type: 'error', message: '施設マスタ取得処理に失敗しました' })
            }
            
        }

        onMounted(()=>{
            getFacilities()
            handleSearch()
        })

        return { searchCondition, locations, location, facilities,
            form, rules, clearValidate, filter,
            showCreateDialog, showUpdateDialog, showDeleteDialog,
            handleCreate, handleUpdate, handleSearch, handleDelete,
            isEdit, isDialogVisible, isConfirmVisible, isSearchVisible, isDeleteVisible,
            dispColumns }
    },
})
