export default class Location {
    locationID: string
    locationName: string
    locationCode: string
    facilityID: string
    isDeleted: boolean
    facilityName: string

    constructor(locationID: string, locationName: string,locationCode: string, facilityID: string, isDeleted: number, facilityName: string = '') {
        this.locationID = locationID
        this.locationName = locationName
        this.locationCode = locationCode
        this.facilityID = facilityID
        this.isDeleted = isDeleted === 1
        this.facilityName = facilityName
    }
}